<template>
  <div class="app">
    <iframe
      v-if="open1s"
      style="display: none;"
      src="http://xindian.hnzyxsbh.com/test.html"
      frameborder="0"
    >
      <!-- src="http://xindian.hnzyxsbh.com/test.html" -->
      <!-- src='http://xindian.hnzyxsbh.com/test.html'
    src='http://localhost:8080/test.html' -->
    </iframe>
    <toubu></toubu>
    <div style="clear: both;" class="">
      <BDhear :num="4"></BDhear>
    </div>
    <div class="app-xt">
      <div class="c-div" style="position: relative;">
        <div class="wqz">{{ qianzh }}</div>
        <div class="conter">
          <div class="qzFile">
            <div class="qzFile-left">
              <img src="../../assets/imges/pdf.png" alt="" />
              <div>
                <p style="margin-bottom: 5px;">
                  {{ info.filename }}
                </p>
                <p>{{ info.createtime }}</p>
              </div>
            </div>

            <!-- <div class="qzbtn1" @click="qz()">预览</div>
                  <div class="qzbtn2" @click="qz()">下载</div> -->
            <div class="qzbtn" @click="qz()">签章</div>
          </div>

          <div class="heard">
            <img class="imgs1" src="./../../assets/xinde/zuo.jpg" alt="" />
            <div>申请详情</div>
            <img class="imgs2" src="./../../assets/xinde/you.jpg" alt="" />
          </div>
          <div class="list">
            <div class="left">
              <p>标段名称：</p>
              <p>{{ info.biddername }}</p>
            </div>
            <div class="right"></div>
          </div>
          <div class="list">
            <div class="left">
              <p>保函编号：</p>
              <p>{{ info.applyno }}</p>
            </div>
            <!-- <div class="right">
                        <p>申请时间：</p>
                          <p>2023-02-06 16:58:47</p>
                      </div> -->
          </div>
          <div class="list">
            <div class="left">
              <p>保证金额：</p>
              <p>{{ info.bzjamount }}元</p>
            </div>
            <div class="right">
              <p>申请时间：</p>
              <p>{{ info.createtime }}</p>
            </div>
          </div>
          <div class="anniu">
            <!-- {{qianc}} -->
            <div class="btn1" @click="syb()">上一步</div>
            <div class="btn2" @click="next()">下一步</div>
          </div>
        </div>
      </div>
    </div>
    <dinad class="qingchu"></dinad>
    <!-- 弹窗 -->
    <div class="tcBox" v-show="open1">
      <div class="tanc">
        <div class="tanc-heard">
          <div class="tanc-title">协议签章</div>
          <div class="tanc-colse" @click="colseView()">✖</div>
        </div>

        <div class="tanc-conter">
          <input style="display: none;" type="file" id="myFileInput" />
          <button
            style="float: left;"
            type="button"
            @click="tijiao"
            id="qianzhang"
            class="layuibtn"
          >
            签章提交
          </button>
          <div style="float: right; line-height: 40px; margin-top: 10px;">
            注:请点击工具栏上的
            <img
              style="width: 30px; height: 30px;"
              src="./yinzhang.png"
              alt=""
              srcset=""
            />
            按钮执行签章，之后点击[签章提交]按钮完成签章操作。
          </div>
          <object
            classid="CLSID:358327D8-B2C5-402F-B1F5-DD89FAA68BFF"
            id="ShareSunReaderSDK"
            style="height: 660px; width: 100%; margin-top: 0px;"
            align="right"
          ></object>
          <!-- <iframe
            :src="qianc"
            frameborder="0"
            width="100%"
            height="100%"
          ></iframe> -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
var timers
import dinad from '../../components/dinad.vue'
import toubu from '../../components/toubu.vue'
import $ from 'jquery'
// import jquery from 'jquery'
import {
  getUserChannels,
  createcontract,
  applyprogress,
  companyst
} from '@/api/user'
import axios from 'axios'
import BDhear from '../../components/BDhear.vue'
import { bhprocess, getbhfile, ukeylist, yulansign } from '@/api/user'
// import "./js/modernizr.js";
// import "./js/main"
export default {
  components: {
    dinad,
    BDhear,
    toubu
  },
  data() {
    return {
      applyno: null,
      qianzh: '未签章',
      qianc: '',
      // qianc:"http://127.0.0.1:5501/index.html?code="+sessionStorage.getItem("applyno"),
      info: {
        // applyno:'',
        // bzjamount:'',
        // timestamp:'',
        // biddername:'',
      },
      tole: '',
      state: null,
      open1: false,
      xieyi: 1,
      open1s: false,
      qitian: true,
      loading: null,
      sxurl: null
    }
  },
  beforeDestroy() {
    clearTimeout(timers)
  },
  async created() {
    this.sxurl = window.location.host + '/test.html'
    // console.log('现在的路径url',this.sxurl);
    var that = this
    window.addEventListener('storage', function (event) {
      if (event.key === 'qicode') {
        const newValue = event.newValue
        const oldValue = event.oldValue
        // 处理 qicode 数据的变化
        console.log('qicode Changed:', newValue)
        if (newValue == '显示') {
          that.loading.close()
          document.querySelector('.tcBox').style.display = 'block'
          that.open1 = true
        }
        console.log('Previous qicode:', oldValue)
        // 执行其他操作...
      }
    })
    this.applyno = sessionStorage.getItem('applyno')
    // console.log(this.applyno, 1231313);
    this.bbv()
    // this.qianc="http://localhost:52330/zhang.html?applyno="+sessionStorage.getItem("applyno")+"&pdf="+sessionStorage.getItem("userurl")
    this.gai()
    this.gongsi()
  },

  methods: {
    async gongsi() {
      var bbc = sessionStorage.getItem('biddercode')
      const { data } = await ukeylist(bbc)
      console.log(data.total, 'gongsi1111111111111111111')
      if (data.total > 0) {
        this.qitian = false
      }
    },
    async gai() {
      var bbc = sessionStorage.getItem('applyno')
      console.log(bbc, 'bbc111111111111111111111')
      const { data } = await bhprocess(bbc)
      console.log('gaizhang底部打印', data.data.xiyi)
      this.xieyi = data.data.xiyi
      if (this.xieyi == 0) {
        this.qianzh = '未签章'
      }
      if (this.xieyi == 1) {
        this.qianzh = '已签章'
      }
    },
    async tijiao() {
      var cion = dakaiqian()
      console.log('cion', cion)
      if (cion == 0) {
        this.$message({
          showClose: true,
          message: '请先进行签章。',
          type: 'error'
        })
      } else {
        this.$message('正在提交签章，请稍等...')

        var cin = {
          applyno: sessionStorage.getItem('applyno'),
          unifyCode: sessionStorage.getItem('biddercode'),
          xieyiId: sessionStorage.getItem('xieyiId')
        }

        const { data } = await yulansign(cin)
        console.log('gaizhang', data)
        if (data.code == 200) {
          this.$message({
            message: '签章成功',
            type: 'success'
          })
          this.gai()
          this.colseView()
        } else {
          this.$message({
            showClose: true,
            message: '签章失败，请重新签章',
            type: 'error'
          })
          this.gai()
        }
      }
    },
    // qz() {
    //   this.xieyi =2

    //   // console.log("this.qianc=",this.qianc);
    //   if (this.xieyi == 1) {
    //     this.$message({
    //       showClose: true,
    //       message: "当前已完成签章无需重复进行签章。",
    //       type: "warning"
    //     });
    //     this.loading.close();
    //   } else {
    //     this.loading = this.$loading({
    //     lock: true,
    //     text: "正在发起签章",
    //     background: "rgba(0, 0, 0, 0.5)"
    //   });
    //     console.log("this.qitian", this.qitian);
    //     this.open1s = true;
    //     // if (this.qitian) {

    //     // } else {
    //     //   this.loading.close();
    //     //   document.querySelector(".tcBox").style.display = "block";
    //     //   this.open1 = true;
    //     // }
    //   }
    // },
    qz() {
      this.xieyi = 2

      // console.log("this.qianc=",this.qianc);
      if (this.xieyi == 1) {
        this.$message({
          showClose: true,
          message: '当前已完成签章无需重复进行签章。',
          type: 'warning'
        })
        this.loading.close()
      } else {
        this.loading = this.$loading({
          lock: true,
          text: '正在发起签章',
          background: 'rgba(0, 0, 0, 0.5)'
        })
        console.log('this.qitian', this.qitian)
        this.open1s = true
        // if (this.qitian) {

        // } else {
        //   this.loading.close();
        //   document.querySelector(".tcBox").style.display = "block";
        //   this.open1 = true;
        // }
      }
    },
    colseView() {
      document.querySelector('.tcBox').style.display = 'none'
      this.bbv()
      this.gai()
    },
    async bbv() {
      var that = this
      var bbc = sessionStorage.getItem('applyno')
      const { data } = await getbhfile(bbc)
      console.log('获取签章申请详情', data)
      that.info = data.data
      // that.info.state=1
      that.info.createtime = that.dateFormat(that.info.createtime)
      return
      $.support.cors = true
      $.ajax({
        url:
          `https://www.hnzyxsbh.com/tripartite/mobile/agreement/getbhfile?applyno=` +
          this.applyno,
        data: '',
        crossDomain: true,
        contentType: 'text/plain',
        type: 'POST',
        dataType: 'jsonp'
      }).done(function (data) {
        // console.log("chaxunshidou签章",data.data);
        that.info = data.data
        // that.info.state=1
        that.info.createtime = that.dateFormat(that.info.createtime)
      })
    },
    syb() {
      this.$router.push('/xieyi')
    },
    next() {
      // this.xieyi=1  //书豪测试
      if (this.xieyi == 1) {
        this.$router.push('/project')
      } else {
        this.$message({
          showClose: true,
          message: '请先进行签章。',
          type: 'error'
        })
        //    this.$notify({
        //   title: "提醒",
        //   message: "请先进行签章。",
        // });
      }
    },
    dateFormat(value) {
      let date = new Date(value * 1000) //时间戳为10位需*1000，时间戳为13位的话不需乘1000
      let y = date.getFullYear()
      let MM = date.getMonth() + 1
      MM = MM < 10 ? '0' + MM : MM
      let d = date.getDate()
      d = d < 10 ? '0' + d : d
      let h = date.getHours()
      h = h < 10 ? '0' + h : h
      let m = date.getMinutes()
      m = m < 10 ? '0' + m : m
      let s = date.getSeconds()
      s = s < 10 ? '0' + s : s
      return y + '-' + MM + '-' + d + ' ' + h + ':' + m + ':' + s //多种时间格式的拼接
    }
  }
}
</script>

<style scoped>
.layuibtn {
  display: inline-block;
  height: 38px;
  line-height: 38px;
  border: 1px solid transparent;
  padding: 0 10px;
  background-color: #fafafa;
  white-space: nowrap;
  text-align: center;
  font-size: 17px;
  border-radius: 2px;
  cursor: pointer;
  border-color: #d2d2d2;
  color: #666;
  font-weight: 700;
  margin: 10px;
  margin-bottom: 0;
  margin-top: 5px;
  margin-bottom: 5px;
}
html {
  width: 100vw;
  width: 100%;
  background: #eeee !important;
}

.app {
  height: 100%;
}

.app-xt {
  width: 100%;
  height: 70%;
  background-color: #fff;
  padding: 15px 0;
}

.c-div {
  width: 70%;
  /* height: 100%; */
  margin: 2vh auto;
  background-color: #f5f6f8;
  padding: 10px 0 10px;
}

.conter {
  width: 80%;
  margin: 0 auto;
}

.heard {
  width: 100%;
  height: 40px;
  line-height: 40px;
  text-align: center;
  margin-top: 20px;
  /* vertical-align: middle; */
  position: relative;
}

.heard .imgs1 {
  height: 2px;
  width: 45%;
  /* float: left; */
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
}

.heard .imgs2 {
  height: 2px;
  width: 45%;
  /* float: left; */
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
}

.heard div {
  height: 100%;
  width: 10%;
  min-width: 80px;
  /* float: left; */
  text-align: center;
  line-height: 40px;
  position: absolute;
  left: 50%;
  top: 0;
  transform: translateX(-50%);
}

.list {
  width: 100%;
  height: 40px;
  line-height: 40px;
  position: relative;
  font-size: 14px;
}

.left {
  width: 55%;
  float: left;
  text-align: left;
}

.left p {
  display: inline-block;
  width: 50%;
  text-align: right;
}

.right p {
  display: inline-block;
  width: 50%;
  text-align: right;
}

.left p:nth-child(2) {
  text-align: left;
}

.right p:nth-child(2) {
  text-align: left;
}

.right {
  width: 45%;
  float: right;
  /* text-align: right; */
}

.tishi {
  width: 100%;
  height: 40px;
  line-height: 40px;
  text-align: center;
  font-size: 18px;
  float: left;
}

.aa {
  color: #565351;
  /* display: inline-block; */
  /* float: left; */
}

.bb {
  color: #81ad99;
  /* display: inline-block; */
  /* float: left; */
}

.list1 {
  width: 100%;
  height: 70px;
  background-color: #e0e9f7;
}

.bottom {
  width: 25%;
  text-align: center;
  float: left;
}

.bottom p {
  height: 35px;
  line-height: 35px;
}

.anniu {
  width: 50%;
  position: relative;
  height: 50px;
  margin: 30px auto;
}

.btn1,
.btn2 {
  width: 80px;
  height: 20px;
  padding: 10px;
  border: 1px solid #4d8efc;
  border-radius: 40px;
  /* margin: 30px auto; */
  text-align: center;
  line-height: 20px;
}
.btn1 {
  position: absolute;
  left: 15%;
}
.btn2 {
  position: absolute;
  right: 15%;
}
.qzFile {
  position: relative;
  width: 100%;
  height: 60px;
  margin-top: 20px;
  background: #fff;
  border: 1px solid #dee7f3;
}
.qzFile-left {
  /* position: absolute;
  top: 50%;
  left: 0px;
  transform: translateY(-50%); */
  float: left;
  height: 60px;
  margin-top: 10px;
  /* width: 80%; */
}
.qzFile-left img {
  width: 40px;
  height: 40px;
  float: left;
  margin-right: 8px;
  margin-left: 8px;
}
.qzFile-left div {
  float: right;
}

.qzbtn {
  width: 80px;
  height: 20px;
  padding: 10px;
  border: 1px solid #4d8efc;
  border-radius: 40px;
  margin: 30px auto;
  text-align: center;
  line-height: 20px;
  position: absolute;
  right: 20px;
  top: -20px;
}
.qzbtn1 {
  width: 80px;
  height: 20px;
  padding: 10px;
  border: 1px solid #4d8efc;
  border-radius: 40px;
  margin: 30px auto;
  text-align: center;
  line-height: 20px;
  position: absolute;
  right: 130px;
  top: -20px;
}
.qzbtn2 {
  width: 80px;
  height: 20px;
  padding: 10px;
  border: 1px solid #4d8efc;
  border-radius: 40px;
  margin: 30px auto;
  text-align: center;
  line-height: 20px;
  position: absolute;
  right: 240px;
  top: -20px;
}
.qingchu {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
}
.wqz {
  width: 60px;
  font-size: 16px;
  color: red;
  position: absolute;
  right: 30px;
  top: 47px;
}

/* 弹窗 */
.tcBox {
  width: 100%;
  height: 100%;
  /* background-color: red; */
  position: fixed;
  top: 0;
  left: 0;
  /* z-index: 2; */
  /* background-color: rgba(0, 0, 0, 0.5); */
}
.tanc {
  width: 860px;
  height: 97%;
  background-color: #cccccc;

  margin: 1vh auto;
}
.tanc-heard {
  width: 100%;
  height: 40px;
  background: #0061c2;
  line-height: 40px;
  font-size: 18px;
  color: #fff;
}
.tanc-title {
  float: left;
  margin-left: 10px;
}
.tanc-colse {
  float: right;
  margin-right: 10px;
}
.tanc-conter {
  width: 100%;
  height: calc(100% - 40px);
  background-color: #f2f2f2;
}
</style>
